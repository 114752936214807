import {IOrderDto, OrderStatus} from "Apis/Models/IOrderDto";
import OrderFunction from "./OrderFunction";
import {
    getOrderConfirmation,
    getOrderDeliveryNote,
    getOrderProductionNote,
    useCreateInvoiceDraft,
} from "Apis/OrderApi";
import {useTranslation} from "react-i18next";
import XIcon from "Assets/SVG/XIcon";
import HandsIcon from "Assets/SVG/HandsIcon";
import useToasts from "Hooks/UseToasts";
import {useState} from "react";
import FileIcon from "Assets/SVG/FileIcon";
import {
    useOrderAccepted,
    useOrderCancelled,
    useOrderDelivered,
    useOrderFinished,
    useOrderProduced
} from "Apis/OrderStatusApi";

interface IProps {
    order: IOrderDto
}

const OrderFunctions = (props: IProps) => {

    const { t } = useTranslation();

    const { showSuccessToast } = useToasts();

    const {order} = props;
    
    const [isDownloadingOrderConfirmation, setIsDownloadingOrderConfirmation] = useState<boolean>(false);
    const [isDownloadingProductionNote, setIsDownloadingProductionNote] = useState<boolean>(false);
    const [isDownloadingDeliveryNote, setIsDownloadingDeliveryNote] = useState<boolean>(false);

    const { mutateAsync: orderAcceptedMutation, isLoading: isAcceptingOrder } = useOrderAccepted();
    const { mutateAsync: orderProducedMutation, isLoading: isProducingOrder } = useOrderProduced();
    const { mutateAsync: orderDeliveredMutation, isLoading: isOrderDelivered } = useOrderDelivered();
    const { mutateAsync: orderFinishedMutation, isLoading: isFinishingOrder } = useOrderFinished();
    const { mutateAsync: orderCancelledMutation, isLoading: isCancelingOrder } = useOrderCancelled();
    
    const { mutateAsync: createOrderInvoiceDraftMutation, isLoading: isCreatingInvoiceDraft } = useCreateInvoiceDraft();
    
    const acceptOrder = async () => {
        if (!isAcceptingOrder) {
            await orderAcceptedMutation(order);

            showSuccessToast(t('orderStatus.acceptedToast'));
        }
    }

    const produceOrder = async () => {
        if (!isProducingOrder) {
            await orderProducedMutation(order);

            showSuccessToast(t('orderStatus.producedToast'));
        }
    }

    const deliverOrder = async () => {
        if (!isOrderDelivered) {
            await orderDeliveredMutation(order);

            showSuccessToast(t('orderStatus.deliveredToast'));
        }
    }

    const finishOrder = async () => {
        if (!isFinishingOrder) {
            await orderFinishedMutation(order);

            showSuccessToast(t('orderStatus.finishedToast'));
        }
    }
    
    const createOrderInvoiceDraft = async () => {
        if (!isCreatingInvoiceDraft) {
            let response = await createOrderInvoiceDraftMutation(order);

            window.open(response.economicUrl, '_blank');
            
            showSuccessToast(t('invoiceCreated'));
        }
    }

    const cancelOrder = async () => {
        if (!isCancelingOrder) {
            await orderCancelledMutation(order);

            showSuccessToast(t('orderStatus.cancelledToast'));
        }
    }
    
    const downloadOrderConfirmation = async () => {
        setIsDownloadingOrderConfirmation(true);
        try {
            await getOrderConfirmation(order, `${t('orderConfirmation')} ${order.orderNumber}.pdf`);
        }
        finally {
            setIsDownloadingOrderConfirmation(false);
        }
    }

    const downloadProductionNote = async (expanded: boolean = false) => {
        setIsDownloadingProductionNote(true);
        try {
            await getOrderProductionNote([order], `${t('productionNote')} ${order.orderNumber}.pdf`, expanded);
        }
        finally {
            setIsDownloadingProductionNote(false);
        }
    }

    const downloadDeliveryNote = async () => {
        setIsDownloadingDeliveryNote(true);
        try {
            await getOrderDeliveryNote(order, `${t('deliveryNote')} ${order.orderNumber}.pdf`);
        }
        finally {
            setIsDownloadingDeliveryNote(false);
        }
    }

    const getAcceptButton = () => {
        if (order?.currentOrderStatus === OrderStatus.Received) {
            return (
                <OrderFunction color={'green'} isLoading={isAcceptingOrder} onClick={acceptOrder} icon={<HandsIcon />} title={t('orderStatus.acceptOrder')} subtitle={t('orderStatus.changeStatus')}  />
            )
        }

        return <></>
    }

    const getProduceButton = () => {
        if (order?.currentOrderStatus === OrderStatus.Accepted) {
            return (
                <OrderFunction color={'green'} isLoading={isProducingOrder} onClick={produceOrder} icon={<HandsIcon />} title={t('orderStatus.produceOrder')} subtitle={t('orderStatus.changeStatus')}  />
            )
        }

        return <></>
    }

    const getDeliverButton = () => {
        if (order?.currentOrderStatus === OrderStatus.Produced) {
            return (
                <OrderFunction color={'green'} isLoading={isOrderDelivered} onClick={deliverOrder} icon={<HandsIcon />} title={t('orderStatus.deliverOrder')} subtitle={t('orderStatus.changeStatus')}  />
            )
        }

        return <></>
    }

    const getFinishButton = () => {
        if (order?.currentOrderStatus === OrderStatus.Delivered) {
            return (
                <OrderFunction color={'green'} isLoading={isFinishingOrder} onClick={finishOrder} icon={<HandsIcon />} title={t('orderStatus.finishOrder')} subtitle={t('orderStatus.changeStatus')}  />
            )
        }

        return <></>
    }

    const getCancelButton = () => {
        if (order?.currentOrderStatus === OrderStatus.Received) {
            return (
                <OrderFunction color={'red'} isLoading={isCancelingOrder} onClick={cancelOrder} icon={<XIcon />} title={t('orderStatus.cancelOrder')} subtitle={t('orderStatus.changeStatus')}  />
            )
        }

        return <></>
    }

    const getOrderConfirmationButton = () => {
        if (order.currentOrderStatus === OrderStatus.Received || order.currentOrderStatus === OrderStatus.Accepted) {
            return (
                <OrderFunction color={'yellow'} isLoading={isDownloadingOrderConfirmation} onClick={downloadOrderConfirmation} icon={<FileIcon />} title={t('orderConfirmation')} subtitle={t('download')}  />
            )
        }
        return <></>
    }

    const getProductionNoteButton = () => {
        if (order.currentOrderStatus === OrderStatus.Accepted || order.currentOrderStatus === OrderStatus.Produced) {
            return (
                <>
                    <OrderFunction color={'yellow'} isLoading={isDownloadingProductionNote} onClick={() => downloadProductionNote(false)} icon={<FileIcon />} title={t('productionNote')} subtitle={t('download')}  />
                    <OrderFunction color={'yellow'} isLoading={isDownloadingProductionNote} onClick={() => downloadProductionNote(true)} icon={<FileIcon />} title={t('productionNoteExpanded')} subtitle={t('download')}  />
                </>
            )
        }
        return <></>
    }

    const getDeliveryNoteButton = () => {
        if (order.currentOrderStatus === OrderStatus.Produced || order.currentOrderStatus === OrderStatus.Delivered) {
            return (
                <OrderFunction color={'yellow'} isLoading={isDownloadingDeliveryNote} onClick={downloadDeliveryNote} icon={<FileIcon />} title={t('deliveryNote')} subtitle={t('download')}  />
            )
        }
        return <></>
    }
    
    const getCreateInvoiceDraftButton = () => {
        if (order?.currentOrderStatus === OrderStatus.Delivered) {
            return (
                <OrderFunction color={'green'} isLoading={isCreatingInvoiceDraft} onClick={createOrderInvoiceDraft} icon={<FileIcon />} title={t('createInvoice')} subtitle={t('invoicing')}  />
            )
        }
    }

    return (
        <div style={{display: 'grid', gap: 10, marginTop: 40}}>
            {getAcceptButton()}
            {getProduceButton()}
            {getProductionNoteButton()}
            {getDeliverButton()}
            {getFinishButton()}
            {getCancelButton()}
            {getOrderConfirmationButton()}
            {getDeliveryNoteButton()}
            {getCreateInvoiceDraftButton()}
        </div>
    )

}

export default OrderFunctions