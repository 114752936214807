import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";
import {getOrderProductionNote, useOrders} from "Apis/OrderApi";
import { OrdersList } from "Features/Orders";
import Pagination from "Components/Pagination";
import OrdersIcon from "Assets/SVG/OrdersIcon";
import useQueryParams from "Hooks/UseQueryParams";
import OrderListFilter from "Features/Orders/Components/OrderListFilter";
import Separator from "../../Components/Separator";
import {IOverviewOrderDto} from "../../Apis/Models/IOrderDto";
import { useState } from "react";
import FlexRow from "Components/FlexRow";
import useToasts from "../../Hooks/UseToasts";
import CreateOrderInvoiceModal from "../../Features/Orders/Components/CreateOrderInvoiceModal";
import ChangeOrderStatusModal from "Features/Orders/Components/ChangeOrderStatusModal";
import Space from "Components/Space";
import CreateBeatdownPurchaseOrderModal from "Features/Orders/Components/CreateBeatdownPurchaseOrderModal";
import {fi} from "date-fns/locale";

const Index = () => {
    
    const { t } = useTranslation();
    const { showErrorToast } = useToasts();

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [beatdownPurchaseOrderModalVisible, setBeatdownPurchaseOrderModalVisible] = useState<boolean>(false);
    const [changeOrderStatusModalVisible, setChangeOrderStatusModalVisible] = useState<boolean>(false);
    const [ filter, setFilter ] = useQueryParams({status: "Open", orderBy: 'CreatedAt', orderByDirection: 'ASCENDING', reverseTagSearch: false});

    const [isDownloadingProductionNote, setIsDownloadingProductionNote] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState<IOverviewOrderDto[]>([]);

    const { data: orders, isLoading } = useOrders(filter);
    
    const navigate = useNavigate();

    const orderByChanged = (orderBy: string, orderByDirection: string) => {
        setFilter({...filter, orderBy, orderByDirection});
    }
    
    const handleCreateInvoiceDraft = () => {
        if (selectedRows.length === 0) {
            showErrorToast(t('noOrdersSelected'));

            return;
        }
        
        setModalVisible(true);
    }

    const changeOrderStatus = () => {
        if (selectedRows.length === 0) {
            showErrorToast(t('noOrdersSelected'));

            return;
        }

        setChangeOrderStatusModalVisible(true);
    }

      const createBeatdownPurchaseOrder = () => {
        if (selectedRows.length === 0) {
            showErrorToast(t('noOrdersSelected'));

            return;
        }

        setBeatdownPurchaseOrderModalVisible(true);
    }

    const downloadProductionNote = async () => {
        if (selectedRows.length === 0) {
            showErrorToast(t('noOrdersSelected'));

            return;
        }

        try {
            setIsDownloadingProductionNote(true);

            await getOrderProductionNote(selectedRows, `${t('productionNote')} ${selectedRows.map(x => x.orderNumber).join(", ")}.pdf`, false);

            setIsDownloadingProductionNote(false);
        }
        catch (e) {
            showErrorToast(t('errorDownloadingProductionNote'));
        }
        finally {
            setIsDownloadingProductionNote(false);
        }
    }
    
    const toggleInvoiceModal = () => {
        setModalVisible(c => !c);
    }

    const toggleBeatdownModal = () => {
        setBeatdownPurchaseOrderModalVisible(c => !c);
    }

    const toggleOrderStatusModal = () => {
        setChangeOrderStatusModalVisible(c => !c);
    }

    
    return (
        <PageLayout title={t('orders')} icon={<OrdersIcon />} header={(
            <Button prefix={<OrdersIcon fill={"#FFF"} />} text={t('createNewOrder')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <OrderListFilter currentValue={filter} filterUpdated={current => setFilter(current, false)} />

                <Separator style={{margin: '15px 0'}} />
                
                <FlexRow justify={"end"}>
                    <Space direction={"horizontal"}>
                        <Button onClick={createBeatdownPurchaseOrder} text={t('createBeatdownPurchaseOrder')} />
                        <Button onClick={changeOrderStatus} text={t('changeOrderStatus')} />
                        <Button loading={isDownloadingProductionNote} onClick={downloadProductionNote} text={t('downloadProductionNote')} />
                        <Button onClick={handleCreateInvoiceDraft} text={t('createInvoiceDraft')} />
                    </Space>
                </FlexRow>

                <Separator style={{margin: '15px 0'}} />

                <OrdersList orders={orders?.items} isLoading={isLoading} allowSelect={true} selected={selectedRows} onSelectChange={setSelectedRows} onOrderByChange={orderByChanged} />

                <Pagination paginatedList={orders} onPageChange={newPage => setFilter({page: newPage})} />

                <CreateBeatdownPurchaseOrderModal visible={beatdownPurchaseOrderModalVisible} orders={selectedRows} toggleVisible={toggleBeatdownModal} onSuccess={() => setSelectedRows([])} />
                <CreateOrderInvoiceModal visible={modalVisible} orders={selectedRows} toggleVisible={toggleInvoiceModal} onSuccess={() => setSelectedRows([])} />
                <ChangeOrderStatusModal visible={changeOrderStatusModalVisible} orders={selectedRows} toggleVisible={toggleOrderStatusModal} onSuccess={() => setSelectedRows([])}   />
            </>
        </PageLayout>
    )
}

export default Index
