import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import {IMutateProductDto, IProductDto} from "./Models/IProductDto";
import {IQueryParams} from "./Models/IQueryParams";
import {saveAs} from "file-saver";

export const PRODUCTS_CACHE_KEY = 'PRODUCTS_CACHE_KEY '
export const useProducts = (queryable: Partial<IQueryParams> | null = null, enabled: boolean = true) => {
    return useQuery<IPaginatedList<IProductDto>>([PRODUCTS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IProductDto>>({
            url: `/products`,
            method: 'GET',
            queryParams: queryable
        })
    }, {
        enabled,
    });
}

export const getProductCSV = async (queryParams: Partial<IQueryParams> | null = null, fileName: string): Promise<any> => {

    const params = new URLSearchParams();

    if (queryParams != null)  {
        Object.keys(queryParams).forEach(key => {
            if (queryParams[key] !== undefined && queryParams[key] !== null) {

                if (Array.isArray((queryParams[key]))) {
                    let valueArray = queryParams[key] as any[]
                    valueArray.forEach((value: any) => {
                        params.append(key, value!.toString());
                    })
                } else {
                    params.append(key, queryParams[key]!.toString());
                }
            }
        });
    }

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/products/csv?${params.toString()}`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/csv;charset=UTF-8' });

    saveAs(blob, fileName);
}

export const useProduct = (id: number) => {
    return useQuery<IProductDto>([PRODUCTS_CACHE_KEY, id], async () => {
        return await request<IProductDto>({
            url: `/products/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateProduct = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (product: IMutateProductDto): Promise<IProductDto> => {
        if (!product.id) {
            return await request<IProductDto>({
                url: `/products`,
                method: 'POST',
                data: product
            })
        } else {
            return await request<IProductDto>({
                url: `/products/${product.id}`,
                method: 'PUT',
                data: product
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRODUCTS_CACHE_KEY)
        }
    })
}

export const useDeleteProduct = () => {

    const queryClient = useQueryClient();

    return useMutation(async (product: IProductDto): Promise<IProductDto> => {
        return await request<IProductDto>({
            url: `/products/${product.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRODUCTS_CACHE_KEY)
        }
    })
}

export const useUnlistProduct = () => {

    const queryClient = useQueryClient();

    return useMutation(async (product: IProductDto): Promise<IProductDto> => {
        return await request<IProductDto>({
            url: `/products/${product.id}/unlist`,
            method: 'PUT',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRODUCTS_CACHE_KEY)
        }
    })
}

export const useChangeProductStatusForMultipleProducts = () => {
    const queryClient = useQueryClient();

    return useMutation(async (model: {productIds: number[], unlisted: boolean}) => {
        return await request({
            url: `/products/unlist`,
            method: 'POST',
            data: model
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRODUCTS_CACHE_KEY)
        }
    })
}

export const useBulkUpdateProducts = () => {
    const queryClient = useQueryClient();

    return useMutation(async (model: any) => {
        return await request({
            url: `/products/bulkUpdate`,
            method: 'POST',
            data: model
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRODUCTS_CACHE_KEY)
        }
    })
}

export const useCheckProductStock = () => {
    return useMutation(async (productId: number) => {
        return await request<{qtyInStock: number}>({
            url: `/products/checkStock`,
            method: 'POST',
            data: {
                productId: productId
            }
        })
    }, {
        onError: (error) => {
            // Do notjing
        }
    })
}